// global overrides for material
@import 'variables.scss'

.mat-datepicker-toggle-default-icon
    width: 24px !important
    fill: $bbraun-gray-90

.footer-button .mat-button-focus-overlay
  background-color: transparent

.mat-tooltip
  max-width: 300px   !important

.mat-select-arrow
    border: none !important

.time-picker
    .mat-form-field-underline
        margin-bottom: 5px !important

    .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper
        margin-top: 0 !important

.button-container .mat-fab
  position: fixed

.mat-snack-bar-container
  color: white

.mat-option-text
    color: $bbraun-gray-90

.mat-icon
    color: $bbraun-gray-90
