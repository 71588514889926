/* stylelint-disable scss/dollar-variable-empty-line-before */
/*Generate font sizes*/
.font-xs {
  font-size: 12px;
}

.font-sm {
  font-size: 14px;
}

.font-md {
  font-size: 18px;
}

.font-lg {
  font-size: 23px;
}

.font-xlg {
  font-size: 30px;
}

html, body {
  font-family: Arial, sans-serif;
  box-sizing: border-box;
}

body {
  color: #636363;
  margin: 0;
  padding: 0;
}

a {
  color: #333333;
  text-decoration: none;
}
a:hover, a:focus {
  color: #333333;
  text-decoration: none;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

span {
  cursor: pointer;
}

.top-margin {
  margin-top: 125px;
}

.top-margin-form {
  margin-top: 55px;
}

.container-form {
  margin: 0 30%;
}

@media only screen and (max-width: 1024px) {
  .container-form {
    margin: 0 15%;
  }
}
.top-margin-40px {
  margin-top: 40px;
}

.full-width {
  width: 100%;
}

.width-90 {
  width: 90%;
}

.width-70 {
  width: 70%;
}

.half-width {
  width: 50%;
}

.width-20 {
  width: 20%;
}

.button-size-28px {
  font-size: 28px !important;
}

.under-construction {
  position: absolute;
  top: 20%;
  left: 47%;
}

.under-construction-image {
  width: 100px;
  height: auto;
}

.under-construction-text {
  position: relative;
  top: 20px;
  right: 28%;
  text-transform: uppercase;
  color: #00b482;
}

.default-pointer {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.margin-auto {
  position: fixed;
  top: -125px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.normal-font-weigth {
  font-weight: 400;
}

.opacity-0-5 {
  opacity: 0.5;
}

.relative {
  position: relative;
}

.wbs-error {
  position: absolute;
  margin-top: -15px;
}

.white {
  color: white;
}

.mt-10px {
  margin-top: 10px;
}

.data-select {
  font-size: 17px;
  max-width: 150px;
}

.data-select-option .mat-option-text {
  font-size: 17px;
}

.grey {
  color: #636363 !important;
}

.material-grey {
  color: rgba(0, 0, 0, 0.54);
}

.ngx-timepicker-control__input {
  background-color: transparent;
}

.position-right {
  float: right !important;
}

.cdk-overlay-pane {
  min-width: 120px !important;
}

.cx-floating-base-button mat-icon {
  color: white !important;
}

mat-table {
  margin-bottom: 70px;
}

/* stylelint-disable scss/dollar-variable-empty-line-before */
.mat-sort-header-arrow {
  display: none;
  visibility: hidden;
}

.mat-cell, .mat-footer-cell, .mat-header-cell {
  min-height: 0;
}

mat-footer-row, mat-row {
  min-height: 44px;
}

.mat-cell {
  padding-right: 0 !important;
  font-size: 14px;
}

.mat-header-cell {
  font-size: 16px;
  font-weight: 400;
}
.mat-header-cell:hover {
  color: #636363;
}

.mat-table {
  margin: 45px 0;
}

mat-cell:last-child, mat-footer-cell:last-child, mat-header-cell:last-child {
  margin-right: 24px;
}

.table-text {
  font-size: 14px;
  color: #818181;
}

.active-table-text {
  color: #00b482;
}

.items-cell {
  color: #711e82;
  font-size: 30px;
  font-weight: bold;
}

.table-icons {
  padding-top: 4px;
  cursor: pointer;
  color: #636363;
}

.click-icon {
  color: #636363;
}
.click-icon:hover {
  color: #595959;
}

.mat-tab-body-wrapper {
  margin-top: -90px;
}

.green {
  color: #00b482;
}

.error {
  color: #e40147;
}

.top-margin-136 {
  margin-top: 136px;
}

/*Generate font sizes*/
/* stylelint-disable scss/dollar-variable-empty-line-before */
@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?zfvwnv");
  src: url("../fonts/icomoon.eot?zfvwnv#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?zfvwnv") format("truetype"), url("../fonts/icomoon.woff?zfvwnv") format("woff"), url("../fonts/icomoon.svg?zfvwnv#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class^=icon-], [class*=" icon-"] {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-reset:before {
  content: "\e900";
}

/* stylelint-disable scss/dollar-variable-empty-line-before */
.timepicker-button span {
  color: #00b482;
}

.timepicker__header {
  background: #00b482 !important;
}

.clock-face__number > span.active {
  background: #00b482 !important;
}

.clock-face__clock-hand {
  background: #00b482 !important;
}
.clock-face__clock-hand:after {
  background: #00b482 !important;
}

.clock-face__clock-hand_minute:before {
  border: 4px solid #00b482 !important;
}

.ngx-material-timepicker-toggle {
  padding: 0 !important;
  fill: #636363 !important;
}

.timepicker-dial__control_editable:focus {
  color: #00b482 !important;
}

.timepicker-dial__control_editable::selection {
  color: #00b482 !important;
}

.ngx-material-timepicker-toggle {
  margin-left: 10px !important;
}

.ngx-timepicker {
  border: 0 !important;
  height: 24px !important;
}

.ngx-timepicker-control--active:after {
  background: #636363 !important;
}

.timepicker-dial__control {
  outline: 0;
}

.ngx-timepicker-control__input {
  color: #636363 !important;
}

/* stylelint-disable scss/dollar-variable-empty-line-before */
.mat-datepicker-toggle-default-icon {
  width: 24px !important;
  fill: #636363;
}

.footer-button .mat-button-focus-overlay {
  background-color: transparent;
}

.mat-tooltip {
  max-width: 300px !important;
}

.mat-select-arrow {
  border: none !important;
}

.time-picker .mat-form-field-underline {
  margin-bottom: 5px !important;
}
.time-picker .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0 !important;
}

.button-container .mat-fab {
  position: fixed;
}

.mat-snack-bar-container {
  color: white;
}

.mat-option-text {
  color: #636363;
}

.mat-icon {
  color: #636363;
}