@import 'variables.scss'
@import 'mixins'

// Generate global font-sizes
@include generate-sizes("font", 12px, 14px, 18px, 23px, 30px)

html, body
  font-family: Arial, sans-serif
  box-sizing: border-box

body
  color: $bbraun-gray-90
  margin: 0
  padding: 0

a
  color: #333333
  text-decoration: none
  &:hover, &:focus
    color: #333333
    text-decoration: none

ul
  list-style: none
  margin: 0
  padding: 0

span
  cursor: pointer

.top-margin
  margin-top: 125px

.top-margin-form
  margin-top: 55px

.container-form
  margin: 0 30%

@media only screen and (max-width: 1024px)
    .container-form
      margin: 0 15%

.top-margin-40px
    margin-top: 40px

.full-width
  width: 100%

.width-90
  width: 90%

.width-70
  width: 70%

.half-width
  width: 50%

.width-20
  width: 20%

.button-size-28px
  font-size: 28px !important

// Under construction styles
.under-construction
  position: absolute
  top: 20%
  left: 47%

.under-construction-image
  width: 100px
  height: auto

.under-construction-text
  position: relative
  top: 20px
  right: 28%
  text-transform: uppercase
  color: $bbraun-green-50

.default-pointer
  cursor: default

.cursor-pointer
  cursor: pointer

.margin-auto
  position: fixed
  // - header heigth
  top: -125px
  bottom: 0
  left: 0
  right: 0
  margin: auto

.normal-font-weigth
  font-weight: 400

.opacity-0-5
  opacity: 0.5

.relative
    position: relative

.wbs-error
    position: absolute
    margin-top: -15px

.white
  color: white

.mt-10px
  margin-top: 10px

.data-select
    font-size: 17px
    max-width: 150px

.data-select-option .mat-option-text
    font-size: 17px

.grey
  color: $bbraun-gray-90 !important

.material-grey
    color: rgba(0, 0, 0, 0.54)

.ngx-timepicker-control__input
  background-color: transparent

.position-right
  float: right !important

.cdk-overlay-pane
    min-width: 120px !important

.cx-floating-base-button
    mat-icon
        color: white !important

mat-table
  margin-bottom: 70px
