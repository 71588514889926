@font-face
  font-family: 'icomoon'
  src: url('../fonts/icomoon.eot?zfvwnv')
  src: url('../fonts/icomoon.eot?zfvwnv#iefix') format("embedded-opentype"), url('../fonts/icomoon.ttf?zfvwnv') format("truetype"), url('../fonts/icomoon.woff?zfvwnv') format("woff"), url('../fonts/icomoon.svg?zfvwnv#icomoon') format("svg")
  font-weight: normal
  font-style: normal


[class^="icon-"], [class*=" icon-"]
  font-family: 'icomoon' !important
  speak: none
  font-style: normal
  font-weight: normal
  font-variant: normal
  text-transform: none
  line-height: 1
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

.icon-reset:before
  content: "\e900"
