@import 'variables.scss'

.timepicker-button span
    color: $bbraun-green-50

.timepicker__header
    background: $bbraun-green-50 !important

.clock-face__number > span.active
    background: $bbraun-green-50 !important

.clock-face__clock-hand
    background: $bbraun-green-50 !important

    &:after
        background: $bbraun-green-50 !important

.clock-face__clock-hand_minute:before
        border: 4px solid $bbraun-green-50 !important

.ngx-material-timepicker-toggle
    padding: 0 !important
    fill: $bbraun-gray-90 !important

.timepicker-dial__control_editable:focus
    color: $bbraun-green-50 !important

.timepicker-dial__control_editable::selection
    color: $bbraun-green-50 !important

.ngx-material-timepicker-toggle
    margin-left: 10px !important

.ngx-timepicker
    border: 0 !important
    height: 24px !important

.ngx-timepicker-control
    &--active
        &:after
            background: $bbraun-gray-90 !important

.timepicker-dial__control
    outline: 0

.ngx-timepicker-control__input
    color: $bbraun-gray-90 !important
