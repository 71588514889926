@import "node_modules/@bbraun/cortex/styles/utilities/variables"

.mat-sort-header-arrow
  display: none
  visibility: hidden

// internet explorer fix
.mat-cell, .mat-footer-cell, .mat-header-cell
  min-height: 0

mat-footer-row, mat-row
  min-height: 44px

.mat-cell
  padding-right: 0 !important
  font-size: $bbraun-font-size-medium

.mat-header-cell
  font-size: $bbraun-font-size-big
  font-weight: 400

  &:hover
    color: $bbraun-gray-90

.mat-table
  margin: 45px 0

mat-cell:last-child, mat-footer-cell:last-child, mat-header-cell:last-child
  margin-right: 24px

.table-text
  font-size: $bbraun-font-size-medium
  color: #818181

.active-table-text
  color: $bbraun-green-50

.items-cell
  color: $bbraun-purple-110
  font-size: 30px
  font-weight: bold

.table-icons
  padding-top: 4px
  cursor: pointer
  color: $bbraun-gray-90


.click-icon
  color: $bbraun-gray-90

  &:hover
    color: #595959

.mat-tab-body-wrapper
  margin-top: -90px

.green
  color: $bbraun-green-50

.error
  color: $bbraun-red-80

.top-margin-136
  margin-top: 136px
